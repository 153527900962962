
// Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,700&amp;subset=cyrillic);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";

// Reset and dependencies
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";

html, body {

  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  height: 100vh;
  margin: 0;
  background-image: url('https://source.unsplash.com/collection/390069/1920x1080');
  background-color: #111;
  background-size: cover;
  text-shadow: 0 2px 4px rgba(0,0,0,0.5);
}

.full-height {
  height: 100vh;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}

.top-right {
  position: absolute;
  right: 10px;
  top: 18px;
}

.content {
  text-align: center;
}

.title {
  font-size: 84px;
  text-shadow: 0 2px 4px rgba(0,0,0,0.5);
}

.links > a {
  color: #636b6f;
  padding: 0 25px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .1rem;
  text-decoration: none;
  text-transform: uppercase;
}

.m-b-md {
  margin-bottom: 30px;
}

.form-control {
  background-color: rgba(0,0,0,0.5);
  border: 1px solid #999;
  color: #fff;
}
